import { AdResponse, HtmlProviderPort } from '../../domain/htmlProviderPort';

export const createOrtbClientHtmlProvider = (url: string): HtmlProviderPort => ({
    async getAd(): Promise<AdResponse> {
        return { creative: await getCreative(url) };
    },
});

const getCreative = async (url: string): Promise<string> => {
    const responseText = await (await fetch(url)).text();

    try {
        return JSON.parse(responseText).creative;
    } catch (err) {
        return responseText;
    }
};
